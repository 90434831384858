<template>
  <div>
    <vs-input
      v-validate="'required|alpha|min:3'"
      data-vv-validate-on="blur"
      name="username"
      icon-no-border
      icon="icon icon-user"
      icon-pack="feather"
      label-placeholder="Username"
      v-model="username"
      class="w-full"
      @keyup.enter="loginJWT"
    />
    <span class="text-danger text-sm">{{ errors.first("username") }}</span>

    <vs-input
      data-vv-validate-on="blur"
      v-validate="'required'"
      type="password"
      name="password"
      icon-no-border
      icon="icon icon-lock"
      icon-pack="feather"
      label-placeholder="Password"
      v-model="password"
      class="w-full mt-6"
      @keyup.enter="loginJWT"
    />
    <span class="text-danger text-sm">{{ errors.first("password") }}</span>

    <div class="flex flex-wrap justify-between my-5">
      <vx-tooltip position="left" text="Ensure you are using your own computer">
        <vs-checkbox v-model="remember_me" class="mb-3"
          >Remember Me</vs-checkbox
        >
      </vx-tooltip>
      <!-- <router-link to="/pages/forgot-password">Forgot Password?</router-link> -->
    </div>
    <div class="flex flex-wrap justify-between pt-2 pb-1">
      <vx-tooltip
        class="cursor-pointer navbar-fuzzy-search ml-4 mt-2"
        :text="db.s"
        position="bottom"
      >
        <feather-icon :class="`text-${db.m}`" icon="DatabaseIcon" />
        <feather-icon :class="`text-${db.m}`" :icon="`${type(db.s)}Icon`" />
      </vx-tooltip>
      <span class="ml-2 mt-1" v-if="db.a">Database Error</span>
      <!-- <vs-button type="border" :disabled="false" @click="syncDB"
        >Sync DB</vs-button
      > -->
      <vs-button :disabled="!validateForm" @click="loginJWT">Login</vs-button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      username: localStorage.getItem("username") || "",
      password: "",
      remember_me: localStorage.getItem("remember_me") == "true" ? true : false,
    };
  },
  computed: {
    validateForm() {
      return !this.errors.any() && this.username != "" && this.password != "";
    },
    db() {
      return this.$store.state.db;
    },
  },
  methods: {
    getLocalStorage(key) 
    {
      return localStorage.getItem(key);
    },
    type(str) {
      var a = str.split(" ");
      var v = false;
      a.map((t) => {
        switch (t) {
          case "download":
            v = "Download";
            break;
          case "upload":
            v = "Upload";
            break;
        }
      });
      return v;
    },
    checkLogin() {
      return this.$pouch.getSession("express", (data) => {
        console.log("Unable to connect to the server. It might be offline.");
        if (data.status == 0) {
          console.log("Unable to connect to the server. It might be offline.");

          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error",
            text: "Unable to connect to the server. It might be offline.",
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "warning",
          });
          return false;
        }

        if (!data.user || !data.hasAccess) {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Login Attempt",
            text: "You are already logged in!",
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "warning",
          });
          return false;
        }

        return true;
      });
    },
    loginJWT() {
      if (!this.checkLogin()) return;

      const payload = {
        remember_me: this.remember_me,
        userDetails: {
          username: this.username,
          password: this.password,
        },
      };

      if (this.validateForm) {
        this.$vs.loading();
        this.$store
          .dispatch("auth/login", payload)
          .then(() => {
            this.$vs.notify({
              title: "Login Success",
              text: "You have been successfully logged in",
              iconPack: "feather",
              icon: "icon-check-circle",
              color: "success",
            });
          })
          .catch((error) => {
            this.$vs.loading.close();
            this.$vs.notify({
              title: "Error",
              text: error,
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "danger",
            });
          });
      } else {
        this.$vs.notify({
          title: "Error",
          text: "Enter valid username and password",
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
        });
      }
    },
    registerUser() {
      if (!this.checkLogin()) return;
      this.$router.push("/register").catch(() => {});
    },
    async syncDB() {
      console.log(this.$pouch);
      let d;
      if (process.env.NODE_ENV === "development") {
        d = `http://localhost:5984/test_db`;
      } else {
        var db = await idb.getRecord("database");
        d = db.db;
      }

      this.$pouch.sync("express", `${d}`, {
        retry: true,
      });
      sync.on("connected", (d) => {
        console.log("succesfully connected to remote");
      });

      sync.on("change", (info) => {
        console.log("Changes detected ", info);
      });
      sync.on("paused", (err) => {
        console.log("Sync paused ", err);
      });
      sync.on("active", () => {
        console.log("Replication resumed");
      });
      sync.on("denied", (err) => {
        console.log("A document failed to replicate", err);
      });
      sync.on("complete", (info) => {
        console.log("Sync complete ", info);
      });
      sync.on("error", (info) => {
        console.log("error pouch ");
        console.log("Sync error ", info);
      });
    },
  },
  destroyed() {
    this.$vs.loading.close();
  },
};
</script>
